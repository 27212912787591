import React from 'react';
import { useParams } from 'react-router-dom';
import photoData from './photoData';
import '../css/Gallery.css';

const Gallery = () => {
    const { type } = useParams();
    const photos = photoData[type] || [];

    return (
        <div className="containerRest">
            <div className="gallery">
                {photos.map((photo, index) => (
                    <img key={index} src={photo} alt={`${type} ${index + 1}`} />
                ))}
            </div>
            <div className="button-container1">
                <button onClick={() => window.history.back()}>Terug</button>
            </div>
        </div>
    );
};

export default Gallery;
