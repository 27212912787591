// src/pages/DynamicPage.js
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import '../css/Home.css';
import Slider from "../components/Slider";
import SEO from '../components/SEO';

const DynamicPage = () => {
    const { location } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount

        const handleScroll = () => {
            const content = document.querySelector('.contentHome-container');
            const contentPosition = content.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.3;

            if (contentPosition < screenPosition) {
                content.classList.add('fade-in');
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Gerald Hoste Vloer en Tegelwerken",
        "description": `Gerald Hoste is een reeds 36 jaar lang actieve vakman in tegelwerken en vloerder in ${location}. Gespecialiseerd in renovaties allerei, nieuwbouw, badkamers, terrassen,... is geen enkele uitdaging te groot! Gestationeerd in ${location} is de zelfstandige aannemer actief in Waasland, Oost-Vlaanderen en dus in heel Vlaanderen!`,
        "url": `https://geraldhoste.be/vloerder/${location}`,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Eigenlostraat 78",
            "addressLocality": "Sint-Niklaas",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9100",
            "addressCountry": "BE"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+32 477 26 35 99",
            "contactType": "Customer Service",
            "areaServed": "BE",
            "availableLanguage": "Dutch"
        }
    };

    const scrollToContent = () => {
        const element = document.getElementById('content-section');
        const headerOffset = 100; // Adjust this value as needed
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

        // Add fade-in class to elements
        setTimeout(() => {
            element.classList.add('fade-in');
        }, 600); // Adjust the delay to match the scroll duration
    };

    return (
        <div>
            <SEO
                title={`Gerald Hoste vloerder ${location} | VLOERWERKEN | VLOERDER-TEGELZETTEN | ${location} | Waasland | vloerder ${location}`}
                description={`Gerald Hoste is een reeds 36 jaar lang actieve vakman in tegelwerken en vloerder in ${location}. Gespecialiseerd in renovaties allerei, nieuwbouw, badkamers, terrassen,... is geen enkele uitdaging te groot! Gestationeerd in ${location} is de zelfstandige aannemer actief in Waasland, Oost-Vlaanderen en dus in heel Vlaanderen!`}
                keywords={`vloerder ${location}, vloeren, vloerwerken, tegelwerken, wandtegels, tegels, ${location}, Waasland, Gerald Hoste, Vakman, Aannemer`}
                canonical={`https://geraldhoste.be/vloerder/${location}`}
                schemaMarkup={schemaMarkup}
            />
            <div>
                <section className="slider-section">
                    <Slider />
                    <div className="overlay">
                        <div className="text-box">
                            <h1 className="title">Vloer- en Tegelwerken Gerald Hoste 4 de generatie</h1>
                            <h1 className="title" style={{ color: '#0076A8' }}>in {location}</h1>
                            <p className="subtitle">gespecialiseerd in renovaties allerei, nieuwbouw, badkamers,
                                terrassen,...</p>
                            <div className="buttons">
                                <Link to="/Contact" className="button contact-button">Contacteer ons vandaag nog voor
                                    vakmanschap in uw huis</Link>
                                <button onClick={scrollToContent} className="button lees-meer-button">Lees meer</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div id="content-section" className="contentHome-container">
                <h4>Vloer- en tegelwerken “GERALD HOSTE” staat voor 4 generaties vakkundige plaatsing van alle type vloer- en wandtegels in het ganse Waasland.</h4>
                <article>
                    <p>Telkens werd de vakkennis van vader op zoon doorgegeven.<br />
                        Iedere generatie gaf met de grootste zorg de oude methodes door aan de nieuwe en maakte telkens ook een punt om
                        innovatie niet uit het oog te verliezen.<br />
                        Deze combinatie van kennis aan oude en nieuwe methodes zorgt er voor dat ieder project kan rekenen op een
                        correct toegepaste plaatsing afgestemd op de omstandigheden en het type tegel.</p><br />
                    <p>Ikzelf vertegenwoordig de 4 de generatie. 36 Jaar geleden begon ik onder de vleugels van mijn vader en ben nu nog steeds gepassioneerd door de stiel.<br />
                        De activiteiten die vandaag aangeboden worden zijn breed georiënteerd ;<span> gerectificeerde tegels, XL formaat, mozaïek,...</span><br />
                        Focus ligt op <span>renovatie, nieuwbouw en restauratiewerken</span> maar evenmin kan u ook opteren voor een <span>totaalproject</span>.<br />
                        Ook elastische voegwerken, in de volksmond – het opspuiten van siliconen, valt onder de aangeboden activiteiten.</p>
                    <br />
                    <p>De kleinschaligheid van het bedrijf laat toe zich te onderscheiden van andere plaatsers:</p>
                    <ul>
                        <li>Open en directe communicatie tussen bouwheer en mezelf ; heb je een vraag dan kan je onmiddellijk een antwoord ontvangen</li>
                        <li>Het gebruik van kwalitatieve materialen</li>
                        <li>Eindverantwoordelijkheid onmiddellijk bij mezelf</li>
                        <li>Het hanteren van een correcte prijs</li>
                    </ul>
                </article>
                <p className="last"><span>Bent u op zoek naar een bekwame vloerder in de regio ${location} en het Waasland:</span></p>
                <div className="button-container">
                    <Link to="/Contact" className="plain-link1">neem dan zeker contact op</Link>
                    <Link to="/Realisaties" className="plain-link2">bekijk mijn ruim aanbod aan foto's.</Link>
                </div>
            </div>
        </div>
    );
};

export default DynamicPage;
