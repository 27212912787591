const photoData = {
    badkamers: [
        require('../img/gallery/badkamer/badkamer1.jpg'),
        require('../img/gallery/badkamer/badkamer2.jpg'),
        require('../img/gallery/badkamer/badkamer3.jpg'),
        require('../img/gallery/badkamer/badkamer4.jpg'),
        require('../img/gallery/badkamer/badkamer5.jpg'),
        require('../img/gallery/badkamer/badkamer6.jpg'),
        require('../img/gallery/badkamer/badkamer7.jpg'),
        require('../img/gallery/badkamer/badkamer8.jpg'),
        require('../img/gallery/badkamer/badkamer9.jpg'),
        require('../img/gallery/badkamer/badkamer10.jpg'),
        require('../img/gallery/badkamer/badkamer11.jpg'),
        require('../img/gallery/badkamer/badkamer12.jpg'),
        require('../img/gallery/badkamer/badkamer13.jpg'),
        // more paths...
    ],
    keukens: [
        require('../img/gallery/keuken/keuken1.jpg'),
        require('../img/gallery/keuken/keuken2.jpg'),
        // more paths...
    ],
    terrassen: [
        require('../img/gallery/terras/terras1.jpg'),
        require('../img/gallery/terras/terras2.jpg'),
        // more paths...
    ],
    transformaties: [
        require('../img/gallery/tranformaties/tran1_1.jpg'),
        require('../img/gallery/tranformaties/tran1_2.jpg'),
        // more paths...
    ],
    wcs: [
        require('../img/gallery/wc/wc1.jpg'),
        require('../img/gallery/wc/wc2.jpg'),
        // more paths...
    ],
    woonkamers: [
        require('../img/gallery/woonkamer/woonkamer1.jpg'),
        require('../img/gallery/woonkamer/woonkamer2.jpg'),
        // more paths...
    ]
};

export default photoData;
