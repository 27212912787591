import React from "react";
import SEO from "../components/SEO";
import { Link } from "react-router-dom";
import '../css/Overons.css';

function Overons() {
    const overOnsSchemaMarkup = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Gerald Hoste Vloer en Tegelwerken",
        "description": "Neem contact op met Gerald Hoste Vloer en Tegelwerken in Sint-Niklaas voor uw vloer- en tegelprojecten. Bereikbaar via telefoon, e-mail of bezoek ons kantoor.",
        "url": "https://geraldhoste.be/Overons",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Eigenlostraat 78",
            "addressLocality": "Sint-Niklaas",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9100",
            "addressCountry": "BE"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+32 477 26 35 99",
            "contactType": "Customer Service",
            "areaServed": "BE",
            "availableLanguage": "Dutch"
        }
    };

    return (
        <div className="container">
            <SEO
                title="Over Ons | Gerald Hoste"
                description="Leer Gerald Hoste uw vakman in Sint-Niklaas kennen."
                keywords="over ons, informatie, vloerder, vloeren, vloerwerken, tegelwerken, wandtegels, tegels, Sint-Niklaas, Waasland, Gerald Hoste, Vakman, Aannemer"
                canonical="https://geraldhoste.be/Overons"
                schemaMarkup={overOnsSchemaMarkup}
            />
            <div className="content-container">
                <h4>Vloer- en tegelwerken “GERALD HOSTE” staat voor 4 generaties vakkundige plaatsing van alle type vloer- en wandtegels in het ganse Waasland.</h4>
                <article>
                    <p>Telkens werd de vakkennis van vader op zoon doorgegeven.<br />
                        Iedere generatie gaf met de grootste zorg de oude methodes door aan de nieuwe en maakte telkens ook een punt om
                        innovatie niet uit het oog te verliezen.<br />
                        Deze combinatie van kennis aan oude en nieuwe methodes zorgt er voor dat ieder project kan rekenen op een
                        correct toegepaste plaatsing afgestemd op de omstandigheden en het type tegel.</p><br />
                    <p>Ikzelf vertegenwoordig de 4 de generatie. 36 Jaar geleden begon ik onder de vleugels van mijn vader en ben nu nog
                        steeds gepassioneerd door de stiel.<br />
                        De activiteiten die vandaag aangeboden worden zijn breed georiënteerd ;<span> gerectificeerde tegels, XL formaat, mozaïek,...</span><br />
                        Focus ligt op <span>renovatie, nieuwbouw en restauratiewerken</span> maar evenmin kan u ook opteren voor een
                        <span>totaalproject</span>.<br />
                        Ook elastische voegwerken, in de volksmond – het opspuiten van siliconen, valt onder de aangeboden activiteiten.
                    </p>
                    <br />
                    <p>De kleinschaligheid van het bedrijf laat toe zich te onderscheiden van andere plaatsers:</p>
                    <ul>
                        <li>Open en directe communicatie tussen bouwheer en mezelf ; heb je een vraag dan kan je onmiddellijk een
                            antwoord ontvangen</li>
                        <li>Het gebruik van kwalitatieve materialen</li>
                        <li>Eindverantwoordelijkheid onmiddellijk bij mezelf</li>
                        <li>Het hanteren van een correcte prijs</li>
                    </ul>
                </article>
                <p className="last"><span>Bent u op zoek naar een bekwame vloerder in de regio Waasland:</span></p>
                <div className="button-container">
                    <Link to="/Contact" className="plain-link1">neem dan zeker contact op</Link>
                    <Link to="/Realisaties" className="plain-link2">bekijk mijn ruim aanbod aan foto's.</Link>
                </div>
            </div>
        </div>
    );
}

export default Overons;
