import './App.css';
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import Overons from "./pages/overons";
import Contact from "./pages/Contact";
import Realisaties from "./pages/Realisaties";
import Gallery from './components/Gallery';
import NotFound from "./components/NotFound";
import DynamicPage from "./components/DynamicPage";


const App = () => {
    return (
        <div className="App">
            <Router>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/Overons" element={<Overons/>}/>
                    <Route path="/Realisaties" element={<Realisaties/>}/>
                    <Route path="/gallery/:type" element={<Gallery/>}/>
                    <Route path="/Contact" element={<Contact/>}/>
                    <Route path="/vloerder/:location" element={<DynamicPage />} /> {/* Add this line for dynamic routing */}
                    <Route path="*" element={<NotFound />} /> {/* Updated to use element instead of component */}
                </Routes>
            </Router>
        </div>
    );
};


export default App;
