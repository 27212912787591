import React from "react";
import '../css/Home.css'
import ContactComponent from "../components/ContactComponent";
import SEO from "../components/SEO";

function Contact() {
    const contactSchemaMarkup  = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Gerald Hoste Vloer en Tegelwerken",
        "description": "Neem contact op met Gerald Hoste Vloer en Tegelwerken in Sint-Niklaas voor uw vloer- en tegelprojecten. Bereikbaar via telefoon, e-mail of bezoek ons kantoor.",
        "url": "https://geraldhoste.be/Contact",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Eigenlostraat 78",
            "addressLocality": "Sint-Niklaas",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9100",
            "addressCountry": "BE"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+32 477 26 35 99",
            "contactType": "Customer Service",
            "areaServed": "BE",
            "availableLanguage": "Dutch"
        }
    };
    return (
        <div>
            <SEO
                title="Contact | Gerald Hoste"
                description="Neem contact op met Gerald Hoste in Sint-Niklaas voor vragen, afspraken op maat of meer informatie. We staan altijd klaar om u te helpen."
                keywords="contact, vloerder, vloeren, vloerwerken, tegelwerken, wandtegels, tegels, Sint-Niklaas, Waasland, Gerald Hoste, Vakman, Aannemer"
                canonical="https://geraldhoste.be/Contact"
                schemaMarkup={contactSchemaMarkup}
            />
            <div><ContactComponent/></div>
        </div>
    );
}

export default Contact;