import React from 'react';
import '../css/ContactComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactComponent = () => {
    return (
        <div className="contact-page">
            <div className="contact-header">
                <div className="contact-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2528.659504898354!2d4.136088015738119!3d51.16669007958295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c392e08ae622e7%3A0x519cba3890b7b8b4!2sEigenlostraat%2078%2C%209100%20Sint-Niklaas%2C%20Belgium!5e0!3m2!1sen!2sus!4v1629887365670!5m2!1sen!2sus"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Maps"
                    ></iframe>
                </div>
            </div>
            <div className="contact-details">
                <div className="contact-item">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                    <h3>Adres</h3>
                    <p>Eigenlostraat 78, 9100 Sint-Niklaas</p>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon icon={faPhone} className="icon" />
                    <h3>Bel Ons</h3>
                    <p><a href="tel:+32477263599">+32 477 26 35 99</a></p>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                    <h3>Email Ons</h3>
                    <p><a href="mailto:gerald.hoste@skynet.be">gerald.hoste@skynet.be</a></p>
                </div>
            </div>
        </div>
    );
};

export default ContactComponent;
