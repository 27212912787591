import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Realisaties.css';

import badkamer4 from '../img/gallery/badkamer/badkamer4.jpg';
import keuken from '../img/gallery/keuken/keuken3.jpg';
import terras from '../img/gallery/terras/terras2.jpg';
import transformatis from '../img/gallery/tranformaties/tran1_2.jpg';
import wc from '../img/gallery/wc/wc2.jpg';
import woonkamer from '../img/gallery/woonkamer/woonkamer4.jpg';
import SEO from "../components/SEO";

const galleries = [
    { title: 'Badkamers', image: badkamer4, type: 'badkamers' },
    { title: 'Keukens', image: keuken, type: 'keukens' },
    { title: 'Terrassen', image: terras, type: 'terrassen' },
    { title: 'Transformaties', image: transformatis, type: 'transformaties' },
    { title: 'WC\'s', image: wc, type: 'wcs' },
    { title: 'Woonkamers', image: woonkamer, type: 'woonkamers' },
];


const Realisaties = () => {
    const navigate = useNavigate();

    const navigateToGallery = (type) => {
        navigate(`/gallery/${type}`);
    };
    const realisatiesSchemaMarkup  = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Gerald Hoste Vloer en Tegelwerken",
        "description": "Realisaties van Gerald Hoste uw vakman in Sint-Niklaas.",
        "url": "https://geraldhoste.be/Realisaties",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Eigenlostraat 78",
            "addressLocality": "Sint-Niklaas",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9100",
            "addressCountry": "BE"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+32 477 26 35 99",
            "contactType": "Customer Service",
            "areaServed": "BE",
            "availableLanguage": "Dutch"
        }
    };

    return (
        <div>
            <SEO
                title="Realisaties | Gerald Hoste"
                description="Realisaties van Gerald Hoste uw vakman in Sint-Niklaas."
                keywords="realisaties, informatie, vloerder, vloeren, vloerwerken, tegelwerken, wandtegels, tegels, Sint-Niklaas, Waasland, Gerald Hoste, Vakman, Aannemer"
                canonical="https://geraldhoste.be/Realisaties"
                schemaMarkup={realisatiesSchemaMarkup}
            />
        <div className="gallery-container">
            {galleries.map((gallery) => (
                <div key={gallery.type} className="gallery-item" onClick={() => navigateToGallery(gallery.type)}>
                    <img src={gallery.image} alt={gallery.title} className="gallery-image" />
                    <div className="gallery-title">{gallery.title}</div>
                </div>
            ))}
        </div>

    </div>
    );
};

export default Realisaties;
