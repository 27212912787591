import React, { useEffect, useState } from 'react';
import "../css/Slider.css";

function Slider() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [
        require("../img/gallery/badkamer/badkamer1.jpg"),
        require("../img/gallery/terras/terras2.jpg"),
        require("../img/gallery/wc/wc2.jpg"),
        require("../img/gallery/woonkamer/woonkamer4.jpg"),
        require("../img/gallery/badkamer/badkamer6.jpg")
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2500); // Change image every 2500 milliseconds (2.5 seconds)

        return () => clearInterval(timer);
    }, [images.length]); // Dependency on images.length to ensure effect runs on mount and cleanup on unmount

    return (
        <section className="container">
            <div className="slider-wrapper">
                <div className="slider">
                    {images.map((src, index) => (
                        <img key={`slide-${index}`} src={src} alt={`slide-${index}`} className={index === currentIndex ? 'active' : ''}/>
                    ))}
                </div>
                <div className="slider-nav">
                    {images.map((_, index) => (
                        <React.Fragment key={`radio-${index}`}>
                            <input type="radio" name="slider-radio" id={`radio-${index}`} checked={index === currentIndex} readOnly />
                            <label htmlFor={`radio-${index}`}></label>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Slider;
